import React from 'react';
import styled from 'styled-components';
import 'normalize.css';
import Nav from './Nav';
import Footer from './Footer';
import GlobalStyles from '../styles/GlobalStyles';

const ContentStyles = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1000px;
  padding: 80px 40px;
  @media (max-width: 480px) {
    padding: 80px 16px;
  }
`;

export default function Layout({ children }) {
  return (
    <>
      <GlobalStyles />
      <Nav />
      <ContentStyles>{children}</ContentStyles>
      <Footer />
    </>
  );
}
