import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  :root {
      --black: #000;
      --grey: #484848;
  }
  html {
      font-size: 14px;
  }
  body {
      box-sizing: border-box;
      min-height: 100vh;
      position: relative;
      background: white;
  }
  * {
      box-sizing: inherit;
  }
  footer {
      position: absolute;
      bottom: 0;
      width: 100%;
  }
  .center {
      text-align: center;
  }
`;

export default GlobalStyles;
