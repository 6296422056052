/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import logo from '../assets/images/logo.png';
import shakingpo from '../assets/images/shakingpo.png';
import weibo from '../assets/images/weibo.png';
import kuaikan from '../assets/images/kuaikan.png';
import twitter from '../assets/images/twitter.png';
import instagram from '../assets/images/instagram.png';
import wechat from '../assets/images/wechat.png';
import qrcode from '../assets/images/wechat-qrcode.png';

const TopBarStyles = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: white;
  .topbar {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    padding: 16px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  @media (max-width: 480px) {
    .topbar {
      padding: 8px 16px;
    }
  }
`;

const LogoAndNameStyles = styled.div`
  display: flex;
  align-items: center;
  .logo {
    width: 48px;
    height: 48px;
    background: url(${logo}) no-repeat;
    background-size: 48px;
  }
  .name {
    width: 93px;
    height: 20px;
    background: url(${shakingpo}) no-repeat;
    background-size: 93px;
    margin-left: 8px;
  }
`;

const NavStyles = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  a {
    text-decoration: none;
    font-size: 16px;
    line-height: 24px;
    font-weight: normal;
    color: var(--black);
    margin-right: 20px;
  }
  .menuBtn {
    font-size: 16px;
    line-height: 24px;
    font-weight: normal;
    color: var(--black);
    cursor: pointer;
  }
  .contactMenu {
    display: none;
  }
  .contactMenu.open {
    display: grid;
    position: absolute;
    top: 42px;
    right: -10px;
    z-index: 10;
    width: 210px;
    background: #f8f8f8;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    overflow: hidden;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 80px 80px 200px 48px;
    grid-gap: 1px;
    .socialLink {
      width: 100%;
      height: 80px;
      text-indent: -999px;
      overflow: hidden;
    }
    .kuaikan {
      background: white url(${kuaikan}) center no-repeat;
      background-size: 34px;
    }
    .weibo {
      background: white url(${weibo}) center no-repeat;
      background-size: 34px;
    }
    .twitter {
      background: white url(${twitter}) center no-repeat;
      background-size: 34px;
    }
    .instagram {
      background: white url(${instagram}) center no-repeat;
      background-size: 30px;
    }
    .wechat {
      display: flex;
      background-color: white;
      grid-column: 1 / span 2;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .wechatTitle {
        height: 30px;
        background: url(${wechat}) left center no-repeat;
        background-size: 30px;
        font-size: 16px;
        font-weight: bold;
        line-height: 30px;
        padding-left: 40px;
        margin-bottom: 10px;
      }
      .qrcode {
        width: 130px;
        height: 130px;
        background: url(${qrcode}) no-repeat;
        background-size: cover;
      }
    }
    .closeBtn {
      background-color: white;
      grid-column: 1 / span 2;
      text-align: center;
      color: var(--black);
      font-size: 16px;
      font-weight: bold;
      line-height: 48px;
      cursor: pointer;
      :hover {
        color: var(--grey);
      }
    }
  }
`;

export default function Nav() {
  const [menuActive, setMenuActive] = useState(false);
  console.log(menuActive);
  console.log(setMenuActive);
  return (
    <TopBarStyles>
      <div className="topbar">
        <LogoAndNameStyles>
          <Link to="/">
            <div className="logo" />
          </Link>
          <Link to="/">
            <div className="name" />
          </Link>
        </LogoAndNameStyles>
        <NavStyles>
          <Link to="/">首页</Link>
          <div className="menuBtn" onClick={() => setMenuActive(!menuActive)}>
            联系我
          </div>
          <div className={`contactMenu ${menuActive ? 'open' : ''}`}>
            <a
              href="https://www.kuaikanmanhua.com/web/topic/11164"
              className="socialLink kuaikan"
            >
              快看
            </a>
            <a href="https://weibo.com/shakingpo" className="socialLink weibo">
              微博
            </a>
            <a
              href="https://twitter.com/shakingpo"
              className="socialLink twitter"
            >
              twitter
            </a>
            <a
              href="https://www.instagram.com/theshakingpo"
              className="socialLink instagram"
            >
              instagram
            </a>
            <div className="wechat">
              <div className="wechatTitle">微信公众号</div>
              <div className="qrcode" />
            </div>
            <div
              className="closeBtn"
              onClick={() => {
                setMenuActive(false);
              }}
            >
              关闭
            </div>
          </div>
        </NavStyles>
      </div>
    </TopBarStyles>
  );
}
